<template>
  <div class='sub-page sub-page-leftmenu'>
    <div class='sub-tab-wrap'>
      <DxTabPanel
          id="tabpanel"
          :selected-index.sync="selectedIndex"
          :data-source='tabs'
          :focusStateEnabled='true'
        >
        <template #title="{ data: tab }">
        <div class='sub-title-wrap-tab'>
          <ul>
            <!--
            <li class='float-left'><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M610.5 373.3c2.6-14.1 2.6-28.5 0-42.6l25.8-14.9c3-1.7 4.3-5.2 3.3-8.5-6.7-21.6-18.2-41.2-33.2-57.4-2.3-2.5-6-3.1-9-1.4l-25.8 14.9c-10.9-9.3-23.4-16.5-36.9-21.3v-29.8c0-3.4-2.4-6.4-5.7-7.1-22.3-5-45-4.8-66.2 0-3.3.7-5.7 3.7-5.7 7.1v29.8c-13.5 4.8-26 12-36.9 21.3l-25.8-14.9c-2.9-1.7-6.7-1.1-9 1.4-15 16.2-26.5 35.8-33.2 57.4-1 3.3.4 6.8 3.3 8.5l25.8 14.9c-2.6 14.1-2.6 28.5 0 42.6l-25.8 14.9c-3 1.7-4.3 5.2-3.3 8.5 6.7 21.6 18.2 41.1 33.2 57.4 2.3 2.5 6 3.1 9 1.4l25.8-14.9c10.9 9.3 23.4 16.5 36.9 21.3v29.8c0 3.4 2.4 6.4 5.7 7.1 22.3 5 45 4.8 66.2 0 3.3-.7 5.7-3.7 5.7-7.1v-29.8c13.5-4.8 26-12 36.9-21.3l25.8 14.9c2.9 1.7 6.7 1.1 9-1.4 15-16.2 26.5-35.8 33.2-57.4 1-3.3-.4-6.8-3.3-8.5l-25.8-14.9zM496 400.5c-26.8 0-48.5-21.8-48.5-48.5s21.8-48.5 48.5-48.5 48.5 21.8 48.5 48.5-21.7 48.5-48.5 48.5zM224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm201.2 226.5c-2.3-1.2-4.6-2.6-6.8-3.9l-7.9 4.6c-6 3.4-12.8 5.3-19.6 5.3-10.9 0-21.4-4.6-28.9-12.6-18.3-19.8-32.3-43.9-40.2-69.6-5.5-17.7 1.9-36.4 17.9-45.7l7.9-4.6c-.1-2.6-.1-5.2 0-7.8l-7.9-4.6c-16-9.2-23.4-28-17.9-45.7.9-2.9 2.2-5.8 3.2-8.7-3.8-.3-7.5-1.2-11.4-1.2h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c10.1 0 19.5-3.2 27.2-8.5-1.2-3.8-2-7.7-2-11.8v-9.2z"/></svg>
            </li>-->
            <li class='float-left sub-title'>{{tab.title}}</li>
          </ul>
          </div>
        </template>
      </DxTabPanel>
    </div>

    <div class='sub-content'> <!-- 여기까지 page header 이하 수정해서 사용-->
      <div class='grid-wrap'>
        <dx-data-grid ref='refScrapCostGrid'
          :data-source='pressPurchasePriceInfoList'
          :show-borders = 'true' :showRowLines = 'false' :showColumnLines='true'
          :hover-state-enabled = "true" :allowColumnResizing = 'true'
          column-resizing-mode = 'widget' :allow-column-reordering = "true"
          :height='gridHeight' :row-alternation-enabled="true"
          :focused-row-enabled="true"
          :focused-row-key.sync='focusedRowKey'
          :auto-navigate-to-focused-row="true"
          key-expr="품번"
          @toolbar-preparing='onToolbarPreparing'
        >
          <DxSearchPanel
            :visible="true"
            placeholder="검색..."
          />
          <DxExport
            :enabled="true"
            file-name="프레스외주품원가정보"
          />
          <DxFilterRow
            :visible="true"
            apply-filter="auto"
          />
          <DxColumn
            :allowEditing='true'
            caption="품번"
            data-field="품번"
            width="110"
          >
          </DxColumn>
          <DxColumn
            :allowEditing='true'
            caption="품명"
            data-field="품명"
            width="180"
          >
          </DxColumn>
          <DxColumn
            :allowEditing='true'
            caption="거래처명"
            data-field="거래처명"
            :visible='true'
            sort-order="asc"
          >
          </DxColumn>
          <DxColumn
            :allowEditing='true'
            caption="재질"
            data-field="재질"
            :visible='true'
            width="130"
          >
          </DxColumn>
          <DxColumn
              :allowEditing='true'
              caption="CVT"
              data-field="cvt"
              format='#,##0'
              dataType="number"
              width="50"
            >
          </DxColumn>
          <DxColumn
              :allowEditing='true'
              caption="구입가"
              data-field="가격"
              format='#,##0.0'
              dataType="number"
            >
          </DxColumn>
          <DxColumn
            caption="원가내역"
            alignment="center"
          >
            <DxColumn
                :allowEditing='true'
                caption="재료비"
                data-field="재료비"
                format='#,##0.00'
                dataType="number"
              >
            </DxColumn>
            <DxColumn
                :allowEditing='true'
                caption="가공비"
                data-field="가공비"
                format='#,##0.00'
                dataType="number"
              >
            </DxColumn>
            <DxColumn
                :allowEditing='true'
                caption="일반관리비"
                data-field="일반관리비"
                format='#,##0.00'
                dataType="number"
              >
            </DxColumn>
            <DxColumn
                :allowEditing='true'
                caption="이윤"
                data-field="이윤"
                format='#,##0.00'
                dataType="number"
              >
            </DxColumn>
            <DxColumn
                :allowEditing='true'
                caption="재료관리비"
                data-field="재료관리비"
                format='#,##0.00'
                dataType="number"
              >
            </DxColumn>
          </DxColumn>
          <DxSummary>
            <DxTotalItem
              value-format='#,##0'
              show-in-column="품번"
              display-format="{0} (건)"
            />
          </DxSummary>
          <dx-paging :enabled="false"/>
          <dx-scrolling mode="virtual" showScrollbar='always' :useNative='false'/>
          <dx-sorting mode="multiple"/>
          <template #scrap-price-date>
            <div class="toolbar-label" style='font-size:1.2rem;margin-left:10px;'>
              <b>  스크랩단가: {{scrapPrice}}원</b>
            </div>
          </template>
        </dx-data-grid>
      </div>
      <loading :active.sync="isLoading" color='rgb(2, 110, 156)' loader='bars'></loading>
    </div> <!--sub-conten end-->

  </div>
</template>

<script>
import Loading from 'vue-loading-overlay'
import DxTabPanel from 'devextreme-vue/tab-panel'
import ConstDef from '../../../../MesSmartVue/src/share/ConstDef'
import DxDataGrid, {
  DxScrolling, DxFilterRow, DxExport, DxTotalItem,
  DxSummary, DxColumn, DxPaging, DxSorting, DxSearchPanel
} from 'devextreme-vue/data-grid'
import AppLib from '../../../../MesSmartVue/src/share/AppLib'

export default {
  name: 'login',
  data: function () {
    return {
      isLoading: false,
      selectedIndex: 0,
      gridHeight: null,
      vendorInfo: null,
      tabs: [
        {
          title: '원가내역'
        }
      ],
      pressPurchasePriceInfoList: null,
      scrapDate: null,
      scrapPrice: null,
      today: null,
      focusedRowKey: null
    }
  },
  components: {
    Loading,
    DxTabPanel,
    DxScrolling,
    DxFilterRow,
    DxExport,
    DxTotalItem,
    DxSummary,
    DxColumn,
    DxPaging,
    DxSorting,
    DxSearchPanel,
    DxDataGrid
  },
  created () {
    document.title = ConstDef.TAB_TITLE
    this.vendorInfo = this.$store.getters.getVendorInfo
    if (this.vendorInfo === undefined || this.vendorInfo === null) {
      this.$router.push('/')
    }
    this.today = AppLib.getToday()
    this.refreshPurchase()
  },
  mounted () {
    this.$Q('.sub-tab-wrap').css('width', (window.innerWidth - ConstDef.LEFT_MENU_WIDTH - 40) + 'px')
    this.gridHeight = (screen.height - 250) * 0.95
  },
  updated () {
  },
  beforeDestroy: function () {

  },
  methods: {
    onToolbarPreparing (e) {
      e.toolbarOptions.items.unshift(
        {
          location: 'before',
          template: function () {
            return '<div class="toolbar-label" style="margin-left:4px;"><b>기준일:</b></div>'
          }
        },
        {
          location: 'before',
          widget: 'dxDateBox',
          options: {
            width: 94,
            value: this.today,
            type: 'date',
            displayFormat: 'yyyy-MM-dd',
            onValueChanged: (args) => {
              this.today = args.value
              this.refreshPurchase()
            }
          }
        },
        {
          location: 'before',
          template: 'scrap-price-date'
        }
      )
    },
    refreshPurchase () {
      this.isLoading = true
      this.$_sp.runNoEncodeFindProc('spFindAllB2bPressPurchasePriceByRealDateAndVendorCodeV2', { 적용일자: this.today, 거래처코드: this.vendorInfo.거래처코드 })
        .then((data) => {
          this.pressPurchasePriceInfoList = this.$_sp.MakeModel(data)
          if (this.pressPurchasePriceInfoList.length > 0) {
            this.scrapPrice = this.pressPurchasePriceInfoList[0].scrapprice
            console.log(this.pressPurchasePriceInfoList[0])
          } else {
            this.$snotify.info('해당 기준일의 외주품 가격정보가 등록되어 있지 않습니다.')
          }
          this.isLoading = false
        })
        .catch(error => {
          this.isLoading = false
          if (error.response.status === ConstDef.GENERRAL_ERROR) {
            this.$snotify.error(error.response.data.message)
          } else {
            this.$snotify.error(`에러코드 : ${error.response.status}`)
          }
        })
    }
  }
}
</script>

<style lang="scss">

</style>
